import React, { useContext, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { ServicesContext } from '../contexts/ServicesContext';
import { Label, Input } from '@rebass/forms/styled-components';
import styled, { ThemeProvider } from 'styled-components';
import { Box, Button, Flex } from 'rebass/styled-components';
import Text from './text';
import {
    parse,
    isValid,
} from 'date-fns/esm';
import { avicennaTheme } from '../theme/GlobalStyle';
import { BookingPlatform } from '@avicennapharmacy/managemymeds-shared';
import { ApiResponse } from '../types/ApiResponse';
import { Pharmacy } from '../types/Pharmacy';
import { Service } from '../types/Service';
import ReCAPTCHA from 'react-google-recaptcha';
import { Checkbox } from '@rebass/forms';

const StyledInput = styled(Input)`
 background-color: white;
 border-color: hsl(0, 0%, 80%);
 font-family: 'Gotham-Light';
 color: ${avicennaTheme.colors.primaryLight};
 ::placeholder {
   color: ${avicennaTheme.colors.primaryLight};
 }
 `;

const StyledInlineInput = styled(StyledInput)`
 display: inline;
 margin-right: 10px;
 `;

type BorderWrapperProps = {
    padding?: boolean;
};

const BorderWrapper = styled.div<BorderWrapperProps>`
 display: flex;
 flex-direction: column;
 justify-content: space-around;
 border: 2px solid ${avicennaTheme.colors.primaryLight};
 border-radius: 8px;
 box-sizing: border-box;
 ${(props) => props.padding && 'padding: 10px;'}
 ${avicennaTheme.breakpoints.mobileTablet} {
   width: 100%;
 }
 .react-datepicker__day--outside-month {
   color: transparent !important;
   pointer-events: none !important;
 }
 `;

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const contactNumRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

type inputProps = {
    labelColour: string | string[] | null;
    platform?: string | string[] | null;
    initialService?: string | null;
};

const PersonalDataForm = ({ labelColour, initialService }: inputProps) => {
    const [error, setError] = useState('');
    const [pharmacy, setPharmacy] = useState<Pharmacy | null>(null);
    const [services, setServices] = useState<Service[]>([]);
    const [service, setService] = useState<Service | null>(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNum, setContactNum] = useState('');
    const [dd, setDd] = useState<string>('');
    const [mm, setMm] = useState<string>('');
    const [yyyy, setYyyy] = useState<string>('');
    const [validEmail, setValidEmail] = useState(false);
    const [validPhone, setValidPhone] = useState(false);
    const [recaptchaPass, setRecaptchaPass] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const { selectedPharmacyApiKey, selectedPharmacyId } = useContext<any>(ServicesContext);
    const [initialised, setInitialised] = useState(false);
    const [personalDataAndMarketingPermission, setPersonalDataAndMarketingPermission] = useState(true);

    const apiHeader = {
        headers: {
            'x-api-key': selectedPharmacyApiKey,
        },
    };

    labelColour = labelColour ?? 'white';

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    useEffect(() => {
        const getPharmacies = async () => {
            try {
                const res = await axios.post<any, AxiosResponse<ApiResponse<Pharmacy[]>>>(
                    `${process.env.GATSBY_BOOKING_SERVICE_URL!}/listowners`,
                    {},
                    apiHeader,
                );

                const matchingPharmacies = res.data.result.filter((p) => p.externalId === selectedPharmacyId);

                if (matchingPharmacies.length === 1) {
                    setPharmacy(matchingPharmacies[0]);
                    setError('');

                } else if (selectedPharmacyId !== '' && matchingPharmacies.length === 0) {
                    if (typeof window !== 'undefined') {
                        window.location.href = 'https://www.managemymeds.co.uk';
                    }
                }
            } catch {
                if (typeof window !== 'undefined') {
                    window.location.href = 'https://www.managemymeds.co.uk';
                }
            }
        };
        getPharmacies();
    }, [selectedPharmacyId]);

    useEffect(() => {
        if (initialService && !initialised && services.length) {
            const selectedService = services.filter((service) => initialService === service.rowKey);

            if(selectedService && selectedService[0].active) {
                setService(selectedService[0] ?? null);
                setInitialised(true);
            } else {
                if (typeof window !== 'undefined') {
                    window.location.href = 'https://www.managemymeds.co.uk';
                }
            }
        }
    }, [services]);

    useEffect(() => {
        const getServices = async () => {
            try {
                const res = await axios.post<any, AxiosResponse<ApiResponse<Service[]>>>(
                    `${process.env.GATSBY_BOOKING_SERVICE_URL!}/listservices`,
                    {
                        externalId: pharmacy?.externalId,
                        serviceType: "All"
                    },
                    apiHeader,
                );
                setServices(res.data.result);
                setError('');
            } catch {
                setError('Failed to load services');
            }
        };

        if (pharmacy) {
            getServices();
        }
    }, [pharmacy]);

    const formSubmit = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity()) {
            if (isValid(parse(`${yyyy}-${mm}-${dd}`, 'yyyy-MM-dd', new Date()))) {
                try {
                    setSubmitting(true);
                    await axios.post(
                        `${process.env.GATSBY_GUEST_BOOKING_SERVICE_URL!}`,
                        {
                            pharmacyId: selectedPharmacyId,
                            externalId: pharmacy?.externalId,
                            resourceId: service?.resourceId,
                            serviceId: service?.rowKey,
                            customerId: email,
                            firstName: firstName,
                            lastName: lastName,
                            dateOfBirth: `${yyyy}-${mm}-${dd}`,
                            contactNumber: contactNum,
                            email,
                            platform: BookingPlatform.Landing,
                            serviceType: "PersonalData"
                        },
                        apiHeader,
                    );
                    setSuccess(true);
                    setError('');
                } catch {
                    setError('Failed to complete booking');
                } finally {
                    setSubmitting(false);
                }
            } else {
                setError('Invalid date of birth');
                setSubmitting(false);
            }
        }
    };

    const updateEmail = (emailInput: string) => {
        setEmail(emailInput);
        setValidEmail(emailRegex.test(emailInput));
    };

    const updateContactNum = (contactNumInput: string) => {
        setContactNum(contactNumInput);
        setValidPhone(contactNumRegex.test(contactNumInput));
    };

    let submitDisabled =
        !pharmacy ||
        !service ||
        !recaptchaPass ||
        !firstName ||
        !lastName ||
        !dd ||
        !mm ||
        !yyyy ||
        !email ||
        !contactNum ||
        !validEmail ||
        !validPhone ||
        !personalDataAndMarketingPermission;

    return (
        <ThemeProvider theme={avicennaTheme}>
            <Flex flexWrap="wrap">
                <Box width={1} pr={3}>
                    <form onSubmit={async (e: any) => await formSubmit(e)}>
                        <Label htmlFor="first">
                            <Text color={labelColour}>First name</Text>
                        </Label>
                        <StyledInput
                            required
                            name="first"
                            placeholder="First name"
                            value={firstName}
                            onChange={(e: any) => setFirstName(e.target.value)}
                        />
                        <Label htmlFor="last" mt={3}>
                            <Text color={labelColour}>Last name</Text>
                        </Label>
                        <StyledInput
                            required
                            name="last"
                            placeholder="Last name"
                            value={lastName}
                            onChange={(e: any) => setLastName(e.target.value)}
                        />
                        <Label htmlFor="last" mt={3}>
                            <Text color={labelColour}>Date of birth</Text>
                        </Label>
                        <StyledInlineInput
                            required
                            name="dd"
                            type="number"
                            min="1"
                            max="31"
                            placeholder="dd"
                            value={dd}
                            onChange={(e: any) => setDd(e.target.value)}
                            style={{ width: '80px' }}
                        />
                        <StyledInlineInput
                            required
                            name="mm"
                            placeholder="mm"
                            type="number"
                            min="1"
                            max="12"
                            value={mm}
                            onChange={(e: any) => setMm(e.target.value)}
                            style={{ width: '80px' }}
                        />
                        <StyledInlineInput
                            required
                            name="yyyy"
                            type="number"
                            placeholder="yyyy"
                            value={yyyy}
                            onChange={(e: any) => setYyyy(e.target.value)}
                            style={{ width: '120px' }}
                        />
                        <Label htmlFor="email" mt={3}>
                            <Text color={labelColour}>Contact email</Text>
                        </Label>
                        <StyledInput
                            required
                            name="email"
                            type="email"
                            placeholder="Contact email"
                            value={email}
                            onChange={(e: any) => updateEmail(e.target.value)}
                        />
                        <Label htmlFor="number" mt={3}>
                            <Text color={labelColour}>Contact number</Text>
                        </Label>
                        <StyledInput
                            required
                            name="number"
                            type="tel"
                            placeholder="Contact number"
                            value={contactNum}
                            onChange={(e: any) => updateContactNum(e.target.value)}
                        />
                        <Box mt={4}>
                            <Label>
                                <Checkbox
                                    required
                                    id="personalData"
                                    name="personalData"
                                    sx={{ color: "#fff"}}
                                    checked={personalDataAndMarketingPermission}
                                    onChange={(e: any) => setPersonalDataAndMarketingPermission(!personalDataAndMarketingPermission)}
                                />
                                <Text pl={3} color={labelColour}>I consent to Avicenna updating my nominated pharmacy & I am happy to receive relevant communications from Avicenna with information on healthcare services and products. I understand I can withdraw my consent at any time.</Text>
                            </Label>
                        </Box>
                        <Box mt={4} mb={2}>
                            <ReCAPTCHA
                                sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY!}
                                onChange={() => setRecaptchaPass(true)}
                            />
                        </Box>
                        {success ? (
                            <>
                                <Text my={4} fontSize={3} color={labelColour}>
                                    Thank you for completing and submitting your information, we will be in touch shortly.
                                </Text>
                            </>
                        ) : (
                            <>
                                {error.trim() !== '' && (
                                    <Box width={1} pr={3} backgroundColor="errorLight" mt={3}>
                                        <Text color="errorDark" p={3}>
                                            {error}
                                        </Text>
                                    </Box>
                                )}
                                <Button
                                    variant="secondary"
                                    mt={4}
                                    type="submit"
                                    disabled={submitDisabled || submitting}
                                    size="large"
                                    backgroundColor={submitDisabled || submitting ? 'secondarylightBg' : 'secondary'}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Text color="primary" fontSize={3} padding="5px" fontFamily="bold">
                                        {submitting ? 'Submitting...' : 'Submit'}
                                    </Text>
                                </Button>
                            </>
                        )}
                    </form>
                </Box>
            </Flex>
        </ThemeProvider>
    );
};
export default PersonalDataForm;
