import React, { useState } from 'react';
import PersonalDataForm from '../components/personalDataForm';
import SEO from '../components/seo';
import { Box, Flex } from 'rebass/styled-components';
import * as queryString from 'query-string';
import { ThemeProvider } from 'styled-components';
import { avicennaTheme } from '../theme/GlobalStyle';
import { PageProps } from 'gatsby';
import ServicesMap from '../components/servicesMap';
import { ServicesContext } from '../contexts/ServicesContext';
import Layout from '../components/layout';
import Main from '../components/main';

const PharmacyService = ({ location }: PageProps) => {
    const [selectedPharmacyId, setSelectedPharmacyId] = useState<string>('');
    const [selectedPharmacyApiKey, setSelectedPharmacyApiKey] = useState<string>('');

    let { service, pharmacy, labelColour, owned, platform } = queryString.parse(location.search);
    if (Array.isArray(service)) service = service.toString(); //to remove type error
    if (Array.isArray(pharmacy)) pharmacy = pharmacy.toString();
    labelColour = labelColour ?? 'white';

    if (!service || !pharmacy) {
        if (typeof window !== 'undefined') {
            window.location.href = 'https://www.managemymeds.co.uk';
        }
    }

    if (!service || !pharmacy) {
        return (
            <>
                <SEO title="Book a Pharmacy Service" />
            </>
        );
    } else {
        return (
            <>
                <SEO title="Book a Pharmacy Service" />
                <Layout>
                    <Main p={[3, 5, 5]} mb={4}>
                        <Flex flexWrap="wrap">
                            <Box width={1}>
                                <ThemeProvider theme={avicennaTheme}>
                                    <Flex flexWrap="wrap">
                                        <ServicesContext.Provider
                                            value={{
                                                selectedPharmacyApiKey,
                                                setSelectedPharmacyApiKey,
                                                selectedPharmacyId,
                                                setSelectedPharmacyId,
                                            }}
                                        >
                                            <Box pr={3} py={3} width={[1, 1 / 2, 1 / 2]}>
                                                <ServicesMap labelColour={labelColour} pharmacy={pharmacy} owned={owned} showSearch={false} />
                                            </Box>
                                            <Box p={3} width={[1, 1 / 2, 1 / 2]}>
                                                {selectedPharmacyId && selectedPharmacyApiKey && (
                                                    <PersonalDataForm labelColour={labelColour} platform={platform} initialService={service} />
                                                )}
                                            </Box>
                                        </ServicesContext.Provider>
                                    </Flex>
                                </ThemeProvider>
                            </Box>
                        </Flex>
                    </Main>
                </Layout>
            </>
        );
    }
};

export default PharmacyService;